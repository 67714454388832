<template>
  <v-skeleton-loader :loading="status.reading" type="article">
    <v-sheet v-bind="propsCompSheet" data-cy="licenses-edit">
      <div class="mb-4">
        <v-form :disabled="formDisabled" @submit.prevent>
          <v-text-field
            v-bind="propsFormFields"
            :label="$t('common.name')"
            v-model="editName"
            :error-messages="editNameErrors"
            @input="$v.editName.$touch()"
            @blur="$v.editName.$touch()"
            type="text"
          >
          </v-text-field>
          <v-textarea
            v-bind="propsFormFields"
            :label="$t('common.description')"
            v-model="editDescription"
            :error-messages="editDescriptionErrors"
            @input="$v.editDescription.$touch()"
            @blur="$v.editDescription.$touch()"
            type="text"
          >
          </v-textarea>
        </v-form>
      </div>

      <div class="d-flex">
        <v-btn
          v-bind="propsButtonTrash"
          :loading="status.loading"
          :disabled="!formRemove"
          @click="clickRemove"
          data-cy="button-remove"
          >{{ $t("common.remove") }}</v-btn
        >
        <div class="flex-grow-1"></div>
        <v-btn
          v-bind="propsButtonAction"
          :loading="status.loading"
          :disabled="!formReadyForSubmit"
          @click="clickUpdate"
          data-cy="button-update"
          >{{ $t("common.update") }}</v-btn
        >
      </div>
    </v-sheet>
  </v-skeleton-loader>
</template>

<script>
import ComponentStatus from "@/modules/base/componentStatus.mixin";
import { required, maxLength } from "vuelidate/lib/validators";

export default {
  name: "LicensesEdit",
  mixins: [ComponentStatus],
  components: {},
  props: {
    objectId: {
      type: String,
      default: "",
    },
  },
  data: () => ({
    changed: false,

    // Edit proxy form data
    editThingyId: "",
    editName: "",
    editDescription: "",
  }),
  validations: {
    editName: {
      required,
      maxLength: maxLength(100),
    },
    editDescription: {
      maxLength: maxLength(1000),
    },
  },
  computed: {
    formDisabled() {
      // if (this.isAdmin) {
      //   return false;
      // }
      return false;
    },
    formRemove() {
      // if (this.isAdmin) {
      //   return true;
      // }
      return true;
    },
    formReadyForSubmit() {
      if (this.status.loading) {
        return false;
      }
      if (this.status.readError) {
        return false;
      }
      // if (this.isAdmin) {
      //   return true;
      // }
      if (this.$v.$invalid) {
        return false;
      }
      if (!this.$v.$anyDirty) {
        return false;
      }
      return true;
    },
    editNameErrors() {
      const errors = [];
      if (!this.$v.editName.$dirty) return errors;
      if (!this.$v.editName.required) {
        errors.push(this.$t("common.errors.required"));
      }
      if (!this.$v.editName.maxLength) {
        errors.push(this.$t("common.errors.maxLength"));
      }
      return errors;
    },
    editDescriptionErrors() {
      const errors = [];
      if (!this.$v.editDescription.$dirty) return errors;
      if (!this.$v.editDescription.maxLength) {
        errors.push(this.$t("common.errors.maxLength"));
      }
      return errors;
    },
    // 2) When the thingy id changes we use the store getter to "get" the
    // object that has (or the temporary one that will have) the thingy data.
    // Note that this is different than the store action used to read the
    // actual data from the backend, they both work "in parallel".
    thingy() {
      return this.$store.getters["licenses/readById"](this.objectId);
    },
  },
  watch: {
    // 3) If the thingy id changes and we have not edit any field we trigger
    // the store action to load the data. Note that this is different than
    // the getter used to display the data, they both work "in parallel".
    objectId: {
      immediate: true,
      handler: function () {
        if (!this.changed) {
          this.setStatusReading();
          this.editThingyId = this.objectId;
          this.$store
            .dispatch("licenses/readById", this.objectId)
            .then((/* result */) => {
              this.setStatusReadSuccess();
            })
            .catch((/* error */) => {
              this.setStatusReadError();
            });
        }
      },
    },
    // 4) In case we load new thingy data (and we are not in the middle of
    // editing) we update our "proxy" form editable variables to reflect
    // what we have just loaded.
    thingy: {
      immediate: true,
      handler: function (newValue /* , oldValue */) {
        this.refreshFormData(newValue);
      },
    },
  },
  created() {},
  methods: {
    inputChanged() {
      this.changed = true;
    },
    refreshFormData(value) {
      let newValue = this.thingy;
      if (value) {
        newValue = value;
      }
      if (!this.changed) {
        this.editName = newValue.name;
        this.editDescription = newValue.description;
      }
    },
    clickUpdate() {
      this.setStatusUpdating();
      let payload = {
        id: this.editThingyId,
        name: this.editName,
        description: this.editDescription,
      };
      this.$store
        .dispatch("licenses/update", payload)
        .then((/* result */) => {
          this.setStatusUpdateSuccess();
          this.$store.commit("status/showSuccess");
          this.changed = false;
          // this.refreshFormData();
        })
        .catch((/* error */) => {
          this.setStatusUpdateError();
          this.$store.commit("status/showError");
        });
    },
    clickRemove() {
      this.setStatusDeleting();
      this.$store
        .dispatch("licenses/delete", this.editThingyId)
        .then((/* result */) => {
          this.setStatusDeleteSuccess();
          this.$store.commit("status/showSuccess");
          this.changed = false;
          this.$router.push({ name: "licenses-index" });
        })
        .catch((/* error */) => {
          this.setStatusDeleteError();
          this.$store.commit("status/showError");
        });
    },
  },
};
</script>

<style scoped lang="scss"></style>
